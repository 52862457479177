import { OrderBy, TableParam } from '../classes/table-param';
import { MainConstants } from '../config/app.constant';
import { Params } from '@angular/router';

export function getDefaultTableParams(sortBy: string = 'name', orderBy?: OrderBy, scope?: string): TableParam {
  return {
    page: 1,
    pageSize: MainConstants.defaultPageSize,
    sortBy: sortBy,
    orderBy: orderBy || OrderBy.Asc,
    searchText: null,
    scope: scope || null,
  };
}

export function getParams(param: Params, sortBy: string = 'name'): TableParam {
  return {
    page: +param['page'] || 1,
    pageSize: +param['pageSize'] || MainConstants.defaultPageSize,
    searchText: param['searchText'] || null,
    sortBy: param['sortBy'] || sortBy,
    orderBy: param['orderBy'] || OrderBy.Asc,
    scope: param['scope'] || null,
  };
}

export function tableParamToQueryParam(tableParams: TableParam): Record<string, string> {
  const currentParams = tableParams;

  let queryParams = {};

  Object.keys(currentParams).forEach((_key) => {
    const key = _key as keyof TableParam;

    if (currentParams[key] !== null && currentParams[key] !== 0 && currentParams[key] !== '') {
      queryParams = { ...queryParams, [key]: currentParams[key].toString() };
    }
  });

  return queryParams;
}
