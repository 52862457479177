import { Role } from '../classes/roles';
import { MainModule } from '../pages/homepage/classes/main-module';

export const MainConstants = {
  hideHeaderTitles: ['Login.PageTitle', 'Login.ResetPassword'],
  headerOnlyTitles: ['Report.SpecialisationReport', 'Report.SpecialisationReportDetail'],
  refreshSkippedRoutes: [
    'i18n',
    'refresh',
    'login',
    'reset-password',
    'set-new-password',
    'specialisation-report',
  ],
  defaultPageSizeOptions: [5, 10, 25, 50, 100],
  defaultPageSize: 25,
  sortDisabledColumns: [
    'actions',
    'department',
    'headOfSpecialisation',
    'enName',
    'responsibleDepartment',
    'credit',
    'firstSemester',
    'subjectVersionName',
    'subjectVersionSemesterStatus',
    'site',
    'semester',
    'workSchedule',
    'status',
    'teacherStatus',
    'trainingLevel',
  ],
  sortDisabledColumnsViewTable: ['subjectVersionSemester', 'subjectVersion', 'subjectCode'],
};

export const Routes = {
  home: '/home',
  login: '/login',
  curriculums: '/curriculums',
  subjects: '/subjects',
  semesters: '/semesters',
  departments: '/departments',
  administrators: '/administrators',
  resetPassword: '/reset-password',
  specialisations: '/specialisations',
  teacherStatuses: '/teacher-statuses',
  teachers: '/teachers',
  headOfSpecialisations: '/head-of-specialisations',
  departmentAdministrators: '/department-administrators',
  subjectVersionStatuses: '/subject-version-statuses',
  subjectResponsibles: '/subject-responsible-teachers',
  curriculumVersionStatuses: '/curriculum-version-statuses',
  subjectVersions: '/subject-versions',
  activityCategories: '/activity-categories',
  activityRates: '/activity-rates',
  requirementForms: '/requirement-forms',
  activityTypes: '/activity-types',
  subjectVersionSemesterStatuses: '/subject-version-semester-statuses',
  sites: '/sites',
  workSchedules: '/work-schedules',
  subjectVersionSemesters: '/subject-version-semesters',
  bugReports: '/bug-reports',
  reportBugs: '/report-bug',
  subjectInterdependencyMesh: '/subject-interdependency-mesh',
  users: '/users',
  specialisationReport: '/specialisation-report',
  trainingLanguages: '/training-languages',
  trainingLevels: '/training-levels',
  teacherView: '/teacher-view',
};

export const Endpoints = {
  me: '/me',
  login: '/login',
  logout: '/logout',
  shibLogin: '/shibboleth-login',
  shibLogout: '/shibboleth-logout',
  curriculums: '/curriculum-versions',
  subjects: '/subjects',
  semesters: '/semesters',
  departments: '/departments',
  administrators: '/administrators',
  resetPassword: '/reset-password',
  setNewPassword: '/set-new-password',
  specialisations: '/specialisations',
  headOfSpecialisations: '/head-of-specialisations',
  teacherStatuses: '/teacher-statuses',
  teachers: '/teachers',
  users: '/users',
  departmentAdministrators: '/department-administrators',
  subjectVersionStatuses: '/subject-version-statuses',
  curriculumVersionStatuses: '/curriculum-version-statuses',
  subjectResponsibles: '/subject-responsible-teachers',
  subjectVersions: '/subject-versions',
  activityCategories: '/activity-categories',
  requirementForms: '/requirement-forms',
  activityRates: '/activity-rates',
  activityTypes: '/activity-types',
  subjectVersionSemesterStatuses: '/subject-version-semester-statuses',
  sites: '/sites',
  workSchedules: '/work-schedules',
  subjectVersionSemesters: '/subject-version-semesters',
  reportBugs: '/bug-reports',
  isCurrentHeadOfSpecialisation: 'is-current-head-of-specialisation',
  selectRole: '/select-role',
  specialisationReport: '/specialisation-report',
  trainingLevels: '/training-levels',
  trainingLanguages: '/training-languages',
  teacherSubjects: '/teacher-subjects',
};

export const MainModules: MainModule[] = [
  {
    title: 'Common.MainModules.BaseSettings',
    icon: 'settings',
    isOpen: false,
    isActive: false,
    modules: [
      {
        title: 'Semester.Semesters',
        url: Routes.semesters,
        roles: [Role.Admin],
      },
      {
        title: 'Department.Departments',
        url: Routes.departments,
        roles: [Role.Admin],
      },
      {
        title: 'TeacherStatus.TeacherStatuses',
        url: Routes.teacherStatuses,
        roles: [Role.Admin],
      },
      {
        title: 'SubjectVersionStatus.SubjectVersionStatuses',
        url: Routes.subjectVersionStatuses,
        roles: [Role.Admin],
      },
      {
        title: 'CurriculumVersionStatus.CurriculumVersionStatuses',
        url: Routes.curriculumVersionStatuses,
        roles: [Role.Admin],
      },
      {
        title: 'ActivityCategories.ActivityCategories',
        url: Routes.activityCategories,
        roles: [Role.Admin],
      },
      {
        title: 'RequirementForms.RequirementForms',
        url: Routes.requirementForms,
        roles: [Role.Admin],
      },
      {
        title: 'SubjectVersionSemesterStatuses.SubjectVersionSemesterStatuses',
        url: Routes.subjectVersionSemesterStatuses,
        roles: [Role.Admin],
      },
      {
        title: 'ActivityRates.ActivityRates',
        url: Routes.activityRates,
        roles: [Role.Admin],
      },
      {
        title: 'ActivityTypes.ActivityTypes',
        url: Routes.activityTypes,
        roles: [Role.Admin],
      },
      {
        title: 'Sites.Sites',
        url: Routes.sites,
        roles: [Role.Admin],
      },
      {
        title: 'WorkSchedule.WorkSchedules',
        url: Routes.workSchedules,
        roles: [Role.Admin],
      },
      {
        title: 'TrainingLevel.TrainingLevels',
        url: Routes.trainingLevels,
        roles: [Role.Admin],
      },
      {
        title: 'TrainingLanguage.TrainingLanguages',
        url: Routes.trainingLanguages,
        roles: [Role.Admin],
      },
    ],
  },
  {
    title: 'Common.MainModules.Users',
    icon: 'person',
    isActive: false,
    isOpen: false,
    modules: [
      {
        title: 'User.Users',
        url: Routes.users,
        roles: [Role.Admin],
      },
      {
        title: 'Administrator.Administrators',
        url: Routes.administrators,
        roles: [Role.Admin],
      },
      {
        title: 'Teacher.Teachers',
        url: Routes.teachers,
        roles: [Role.Admin],
      },
      {
        title: 'Specialisation.HeadsOfSpecialisation',
        url: Routes.headOfSpecialisations,
        roles: [Role.Admin],
      },
      {
        title: 'SubjectResponsible.SubjectResponsibles',
        url: Routes.subjectResponsibles,
        roles: [Role.Admin, Role.DepartmentAdministrator],
      },
      {
        title: 'Administrator.DepartmentAdministrators',
        url: Routes.departmentAdministrators,
        roles: [Role.Admin],
      },
    ],
  },
  {
    title: 'Common.MainModules.BugReports',
    icon: 'bug_report',
    isOpen: false,
    isActive: false,
    modules: [
      {
        title: 'Bug.ReportBug',
        url: Routes.reportBugs,
        roles: [Role.Anyone],
      },
      {
        title: 'Bug.BugReports',
        url: Routes.bugReports,
        roles: [Role.Admin],
      },
    ],
  },
  {
    title: 'Common.MainModules.Courses',
    icon: 'school', // school
    isOpen: false,
    isActive: false,
    modules: [
      {
        title: 'Specialisation.Specialisations',
        url: Routes.specialisations,
        roles: [Role.Admin, Role.HeadOfSpecialisation],
      },
      {
        title: 'Report.SpecialisationReportDetail',
        url: Routes.specialisationReport,
        roles: [Role.Anyone],
        icon: 'open_in_new',
      },
      { title: 'Curriculum.Curriculums', url: Routes.curriculums, roles: [Role.Anyone] },
    ],
  },
  {
    title: 'Common.MainModules.Subjects',
    icon: 'library_books',
    isOpen: false,
    isActive: false,
    modules: [
      {
        title: 'Subject.Subjects',
        url: Routes.subjects,
        roles: [
          Role.Admin,
          Role.HeadOfSpecialisation,
          Role.Employee,
          Role.SubjectResponsibleTeacher,
          Role.DepartmentAdministrator,
        ],
      },
      {
        title: 'SubjectVersionSemester.SubjectVersionSemesters',
        url: Routes.subjectVersionSemesters,
        roles: [Role.Anyone],
      },
      {
        title: 'SubjectVersion.SubjectVersions',
        url: Routes.subjectVersions,
        roles: [Role.Anyone],
      },
      {
        title: 'Teacher.TeacherView',
        url: Routes.teacherView,
        roles: [
          Role.Admin,
          Role.HeadOfSpecialisation,
          Role.Employee,
          Role.SubjectResponsibleTeacher,
          Role.DepartmentAdministrator,
        ],
      },
    ],
  },
];

export const ErrorMessages: Record<string, string> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  NameTaken: 'Common.Error.NameTaken',
  'Teacher.CannotDelete': 'Teacher.CannotDelete',
  'Subject.CannotDelete': 'Subject.CannotDelete',
  'SubjectVersion.CannotDelete': 'SubjectVersion.CannotDelete',
  'SubjectResponsibleTeacher.CannotDelete': 'SubjectResponsible.CannotDelete',
  'Teacher.NeptunCodeTaken': 'Teacher.NeptunCodeTaken',
  'Subject.NeptunCodeTaken': 'Subject.NeptunCodeTaken',
  'Specialisation.CodeTaken': 'Specialisation.CodeTaken',
  'SubjectVersionStatus.NameTaken': 'SubjectVersionStatus.NameTaken',
  'Specialisation.CannotDelete': 'Specialisation.CannotDelete',
  'HeadOfSpecialisation.CannotDelete': 'Specialisation.Head.CannotDelete',
  'HeadOfSpecialisation.DatesAlreadyAssignedToTheSpecifiedSpecialisation':
    'Specialisation.DatesAlreadyAssignedToTheSpecifiedSpecialisation',
  'SubjectResponsibleTeacher.DatesAlreadyAssignedToTheSpecifiedSubject':
    'SubjectResponsible.DatesAlreadyAssignedToTheSpecifiedSubject',
  'SubjectVersionSemester.NoNextSemester': 'SubjectVersionSemester.NoNextSemester',
  'SubjectVersionSemester.NoPermission': 'Common.NoPermission',
  'SubjectVersionSemester.ShouldBeUnique': 'SubjectVersionSemester.DuplicateShouldBeUnique',
  'User.CannotDelete': 'User.CannotDelete',
  'SubjectVersionSemester.CannotExportDueToInvalidStatusOrMissingSemester':
    'SubjectVersionSemester.CannotExportDueToInvalidStatusOrMissingSemester',
};

export const GeneralError: string = 'Common.Error.GeneralError';
